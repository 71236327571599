function BrowserInfo() {
    var res = {
        name: "Chrome",
        version: "99",
    };
    var reg;
    var userAgent = self.navigator.userAgent;


    return res;
}

export { //很关键
    LoadObj,
    OnClickSelectCertificate,
    OnClickGetCertInfo
}

var CryptoKit;
function LoadObj() {
    try {
        var browser = BrowserInfo();
        if (("Edge(Chromium)" == browser.name) || ("Chrome" == browser.name && parseInt(browser.version) > 87) || ("Firefox" == browser.name && parseInt(browser.version) > 52)) {
            CryptoKit = new nmCryptokit(browser.name);

          return   CryptoKit.checkExtensionInstall(browser.name).then(function(response) {

             return    CryptoKit.init().then(function() {
                    var subjectDNFilter ='';
                    var issuerDNFilter = '';
                    var serialNumFilter ='';
                    var cspList = 'Utap CSP v6.0';
                   return  CryptoKit.selectCertificate(subjectDNFilter, issuerDNFilter, serialNumFilter, cspList).then(function(response) {
                        console.log( response);
                        var InfoTypeID = 'SubjectDN'
                        return  CryptoKit.getSignCertInfo(InfoTypeID).then(function(response) {
                            console.log(response)
                            return response.result;
                        }).
                        catch(function(response) {
                            ShowErrorInfo(response);
                        });

                    }).
                    catch(function(response) {
                        console.log(response);
                        ShowErrorInfo(response);
                    });

                }).
                catch(function(response) {

                    alert("Please Install exe!");
                });
            }).
            catch(function(response) {
                installExtension();
            });
        } else {
            alert("Unsupported browser!");
        }
    } catch(e) {
        alert(e);
        return;
    }
}

function installExtension(){
    var browser = BrowserInfo();
    if ("Edge(Chromium)" == browser.name) {
        alert("Please install extension for edge from:https://microsoftedge.microsoft.com/addons/detail/dkfhajmknllkifmojleijifpglgegndo");
    }
    else if ("Firefox" == browser.name) {
      //  document.getElementById("CryptoKitExtension").style.display = "block";
      //  document.getElementById("CryptoKitExtension").href = "https://sczyzx.cn/CryptoKitExtension.SCZYZX.xpi";
    }
    else if ("Chrome" == browser.name) {
        alert("Please install extension for Chrome!");
    }
    else {
        alert("Unsupported browser!");
    }
}

function OnClickSelectCertificate() {

    var subjectDNFilter ='';
    var issuerDNFilter = '';
    var serialNumFilter ='';
    var cspList = 'Utap CSP v6.0';
    CryptoKit.selectCertificate(subjectDNFilter, issuerDNFilter, serialNumFilter, cspList).then(function(response) {
        console.log( response);
    }).
    catch(function(response) {
          console.log(response);
        ShowErrorInfo(response);
    });
}
function ShowErrorInfo(response) {
    if(null == response){
        alert("Extension has been remove!");
    }
    else if(1 == response.errorcode) { // connection error
        alert(response.result);
    }
    else { // host error
        CryptoKit.getLastErrorDesc()
            .then(function (response) {
                alert(response.result);
            });
    }
}

function OnClickGetCertInfo() {

    var InfoTypeID = 'SubjectDN'
   return  CryptoKit.getSignCertInfo(InfoTypeID).then(function(response) {
       console.log(response)
       console.log('22222222')
       return response.result;
    }).
    catch(function(response) {
        ShowErrorInfo(response);
    });
}

var chromeExtension = "flhhehcijmjcdbjceochiachofpeabpk";
var edgeExtension = "dkfhajmknllkifmojleijifpglgegndo";
var productID = "com.cfca.cryptokit.sczyzx";

var extensionName = productID + ".extension";
var reqEventName = productID + ".request";
var respEventName = productID + ".response";

var Browser = {
    IE: "Internet Explorer",
    Edge: "Edge",
    Edg: "Edge(Chromium)",
    Chrome: "Chrome",
    Safari: "Safari",
    Firefox: "Firefox",
};

function GenerateRandomId() {
    var charstring = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    var maxPos = charstring.length;
    var randomId = '';
    for (var i = 0; i < 10; i++) {
        randomId += charstring.charAt(Math.floor(Math.random() * maxPos));
    }
    return randomId;
}

function nmCryptokit(browser) {

    this.browser = browser;
}

// Encapsulate Chrome sendMessage callback to Promise
function SendMessageforChrome(request) {
    return new Promise(function(resolve, reject) {
       window. chrome.runtime.sendMessage(chromeExtension, request,
            function(response) {
                if (response) {
                    if (0 == response.errorcode) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                } else {
                    var result = new Object();
                    result.errorcode = 1;
                    result.result = window.chrome.runtime.lastError.message;
                    reject(result);
                }
            });
    });
}

// Encapsulate Edge sendMessage callback to Promise
function SendMessageforEdge(request) {
    return new Promise(function(resolve, reject) {
        window.chrome.runtime.sendMessage(edgeExtension, request,
            function(response) {
                if (response) {
                    if (0 == response.errorcode) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                } else {
                    var result = new Object();
                    result.errorcode = 1;
                    result.result = window.chrome.runtime.lastError.message;
                    reject(result);
                }
            });
    });
}

// Encapsulate Edge&Firefox event to Promise
function SendMessagebyEvent(request) {
    var event = new CustomEvent(reqEventName, {
        detail: request
    });
    document.dispatchEvent(event);

    return new Promise(function(resolve, reject) {

        var responseEventName = respEventName;
        if (request.funcInfo != undefined && request.funcInfo.randomId != undefined) responseEventName += ("." + request.funcInfo.randomId);

        document.addEventListener(responseEventName,
            function CallBack(e) {
                document.removeEventListener(e.type, CallBack);
                var eJson = JSON.parse(e.detail);
                if (null != eJson && 0 == eJson.errorcode) {
                    resolve(eJson);
                } else {
                    reject(eJson);
                }
            },
            false);
    });
}

function SendMessage(browser, requestJSON) {
    if (Browser.Chrome == browser) {
        return SendMessageforChrome(requestJSON);
    } else if (Browser.Edg == browser) {
        return SendMessageforEdge(requestJSON);
    } else {
        return SendMessagebyEvent(requestJSON);
    }
}

function checkExtension(browser) {
    return new Promise(function(resolve, reject) {
        var result = new Object();
        if (Browser.Chrome == browser || Browser.Edg == browser) {
            // chrome.runtime.sendMessage() could check extension  existence.
            resolve(browser);
        } else if (Browser.Firefox == browser) {
            if (document.getElementById(extensionName)) {
                resolve(browser);
            } else {
                result.errorcode = 2;
                result.result = "Extension does not exist!";
                reject(result);
            }
        } else {
            result.errorcode = 3;
            result.result = "Only support Chrome/Edge/Firefox";
            reject(result);
        }
    });
}

nmCryptokit.prototype.checkExtensionInstall = function(browser) {
    if (Browser.Chrome == browser || Browser.Edg == browser) {
        var request = new Object();
        var funcInfo = new Object();

        funcInfo.
            function = "getExtensionVersion";
        funcInfo.params = null;
        request.action = "getExtensionVersion";
        request.funcInfo = funcInfo;

        return SendMessage(this.browser, request).
        catch(function(error) {
            var validExtension = 2;

            extensionName = productID + ".extension";
            reqEventName = productID + ".request";
            respEventName = productID + ".response";

            return SendMessage(browser, request);
        });
    } else {
        return new Promise(function(resolve, reject) {
            var result = new Object();
            if (Browser.Firefox == browser) {
                if (document.getElementById(extensionName)) {
                   var validExtension = 1;
                    resolve(browser);
                } else if (document.getElementById(productID + ".extension")) {
                    validExtension = 2;

                    extensionName = productID + ".extension";
                    reqEventName = productID + ".request";
                    respEventName = productID + ".response";

                    resolve(browser);
                } else {
                    result.errorcode = 1;
                    result.result = "Extension does not exist!";
                    reject(result);
                }
            } else {

                result.errorcode = 1;
                result.result = "Only support Chrome/Edge/Firefox";
                reject(result);
            }
        });
    }
}

nmCryptokit.prototype.init = function() {

    var browser = this.browser;

    return checkExtension(browser).then(function(browser) {
        var request = new Object();
        request.action = "connect";
        request.host = productID;
        return SendMessage(browser, request);
    }).then(function() {
        var request = new Object();
        var funcInfo = new Object();
        var randomId = GenerateRandomId();
        funcInfo.
            function = "checkHost";
        funcInfo.params = null;
        funcInfo.randomId = randomId;
        request.action = "invoke";
        request.funcInfo = funcInfo;
        return SendMessage(browser, request);
    });
}

nmCryptokit.prototype.uninit = function() {

    var request = new Object();
    request.action = "disconnect";
    request.host = productID;

    return SendMessage(this.browser, request);
}

nmCryptokit.prototype.selectCertificate = function(strSubjectDNFilter, strIssuerDNFilter, strSerialNo, strCSPList) {

    var request = new Object();
    var funcInfo = new Object();
    var paramArr = new Array();
    var randomId = GenerateRandomId();

    paramArr.push(strSubjectDNFilter);
    paramArr.push(strIssuerDNFilter);
    paramArr.push(strSerialNo);
    paramArr.push(strCSPList);

    funcInfo.
        function = "SelectCertificate";
    funcInfo.params = paramArr;
    funcInfo.randomId = randomId;

    request.action = "invoke";
    request.funcInfo = funcInfo;

    return SendMessage(this.browser, request);
}

nmCryptokit.prototype.getSignCertInfo = function(strInfoType) {

    var request = new Object();
    var funcInfo = new Object();
    var paramArr = new Array();
    var randomId = GenerateRandomId();

    paramArr.push(strInfoType);

    funcInfo.
        function = "GetSignCertInfo";
    funcInfo.params = paramArr;
    funcInfo.randomId = randomId;

    request.action = "invoke";
    request.funcInfo = funcInfo;

    return SendMessage(this.browser, request);
}

nmCryptokit.prototype.signMsgPKCS7 = function(strSource, strSelectedAlg, bAttached) {

    var request = new Object();
    var funcInfo = new Object();
    var paramArr = new Array();
    var randomId = GenerateRandomId();

    paramArr.push(strSource);
    paramArr.push(strSelectedAlg);
    paramArr.push(bAttached);

    funcInfo.
        function = "SignMsgPKCS7";
    funcInfo.params = paramArr;
    funcInfo.randomId = randomId;

    request.action = "invoke";
    request.funcInfo = funcInfo;

    return SendMessage(this.browser, request);
}

nmCryptokit.prototype.signHashMsgPKCS7Detached = function(bstrSourceData, bstrHashAlg) {

    var request = new Object();
    var funcInfo = new Object();
    var paramArr = new Array();
    var randomId = GenerateRandomId();

    paramArr.push(bstrSourceData);
    paramArr.push(bstrHashAlg);

    funcInfo.
        function = "SignHashMsgPKCS7Detached";
    funcInfo.params = paramArr;
    funcInfo.randomId = randomId;

    request.action = "invoke";
    request.funcInfo = funcInfo;

    return SendMessage(this.browser, request);
}

nmCryptokit.prototype.getVersion = function() {

    var request = new Object();
    var funcInfo = new Object();
    var randomId = GenerateRandomId();

    funcInfo.
        function = "GetVersion";
    funcInfo.params = null;
    funcInfo.randomId = randomId;

    request.action = "invoke";
    request.funcInfo = funcInfo;

    return SendMessage(this.browser, request);
}

nmCryptokit.prototype.getLastErrorDesc = function() {

    var request = new Object();
    var funcInfo = new Object();
    var randomId = GenerateRandomId();

    funcInfo.
        function = "GetLastErrorDesc";
    funcInfo.params = null;
    funcInfo.randomId = randomId;

    request.action = "invoke";
    request.funcInfo = funcInfo;

    return SendMessage(this.browser, request);
}